<template>
    <div class="ticket-info">
        <div v-if="!status">
            Загрузка...
        </div>
        <div v-else>
            <p>Статус по заявке {{ ticket }}</p>
            <p><span class="ticket-info__status">{{ message }}</span></p>
            <p v-if="status === 'attention'">
                Свяжитесь с нами по телефону
                <a :href="`tel:${phoneLink}`">
                    {{ phone }}
                </a>
            </p>
        </div>
    </div>
</template>

<script>
import getTicket from '@/api/ticket'
import contacts from '@/mixins/contacts';

import './ticket-info.scss';

export default {
    mixins: [
        contacts,
    ],
    data() {
        return {
            status: '',
            ticket: ''
        }
    },
    async mounted() {
        const data = await getTicket(this.$route.params.uid);

        this.ticket = data.ticket
        this.status = data.status
    },
    computed: {
        message() {
            switch (this.status) {
                case 'success':
                    return 'подписка отменена';
                case 'attention':
                    return 'требует уточнений';
                case 'notFound':
                    return 'не найдено';
                default:
                    return 'в работе'
            }
        }
    }
}
</script>